import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "upsert-users-when-they-login"
    }}>{`Upsert users when they login`}</h1>
    <p>{`We start with an example that's mostly handled for us by Auth0, but is still a good way to talk about the basics. You most often want to have the concept of a user in your own database too.`}</p>
    <h2 {...{
      "id": "prepare-graphql-mutation"
    }}>{`Prepare GraphQL mutation`}</h2>
    <Vimeo id="392217039" mdxType="Vimeo" />
    <h2 {...{
      "id": "mutation-resolver"
    }}>{`Mutation resolver`}</h2>
    <Vimeo id="392217547" mdxType="Vimeo" />
    <Vimeo id="392217658" mdxType="Vimeo" />
    <h2 {...{
      "id": "create-dynamodb-table"
    }}>{`Create DynamoDB table`}</h2>
    <p>{`Here you'll see me copy paste a dynamodb.ts file from an older project. After reviewing this step I decided to open source that file as a library and recommend you install that instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`# in <project>/server
yarn add simple-dynamodb
`}</code></pre>
    <Vimeo id="392218095" mdxType="Vimeo" />
    <h2 {...{
      "id": "first-working-test-of-your-resolver"
    }}>{`First working test of your resolver`}</h2>
    <Vimeo id="392218388" mdxType="Vimeo" />
    <h2 {...{
      "id": "write-mutation-to-actually-upsert"
    }}>{`Write mutation to actually upsert`}</h2>
    <Vimeo id="392219155" mdxType="Vimeo" />
    <h2 {...{
      "id": "verify-upserting-users-works"
    }}>{`Verify upserting users works`}</h2>
    <Vimeo id="392219401" mdxType="Vimeo" />
    <h2 {...{
      "id": "running-mutation-in-usemutation"
    }}>{`Running mutation in useMutation`}</h2>
    <Vimeo id="392220152" mdxType="Vimeo" />
    <h2 {...{
      "id": "extract-logic-into-custom-hooks"
    }}>{`Extract logic into custom hooks`}</h2>
    <Vimeo id="392244001" mdxType="Vimeo" />
    <h2 {...{
      "id": "how-useauth-and-useupdateuser-work-together"
    }}>{`How useAuth and useUpdateUser work together`}</h2>
    <Vimeo id="392479442" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      